import styled from 'styled-components';

function GratitudesList({ gratitudes }) {
  function list() {
    if (gratitudes.length === 0) {
      return <Gratitude>Write your first gratitude above &#9757;</Gratitude>;
    }

    return gratitudes.map((gratitude, index) => {
      return <Gratitude key={index}>{gratitude}</Gratitude>;
    });
  }

  return (
    <Gratitudes>
      <GratitudesTitle>Gratitudes</GratitudesTitle>
      {list()}
    </Gratitudes>
  );
}

export default GratitudesList;

const Gratitude = styled.div`
  border: 1px solid lightGrey;
  box-shadow: 2px 4px #f6f6f6;
  padding: 10px 18px;
  border-radius: 20px;
  margin-top: 10px;
`;

const GratitudesTitle = styled.div`
  font-weight: 700;
`;

const Gratitudes = styled.div`
  margin: 50px 0 30px;
`;
