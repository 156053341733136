import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Footer from '../Components/Footer';

export default function LandingPage() {
  const callToAction = (text) => {
    return (
      <>
        <ButtonWrap>
          <MainButton>
            <StyledLink to="/about">{text}</StyledLink>
          </MainButton>
        </ButtonWrap>
        <SmallText>It's 100% free, no sign up needed.</SmallText>
      </>
    );
  };

  return (
    <>
      <Wrap>
        <Section>
          <Header>
            Improve how you <Gradient>feel</Gradient> everyday
          </Header>
          <Text>
            Helping men to make time for their wellbeing & increase their
            energy, mood and health.
          </Text>
          {callToAction("I'll try it")}
        </Section>
        <Section>
          <SubHeader>Is this you?</SubHeader>
          <Point>
            <Emoji>&#129300;</Emoji>
            <div>You often feel like your mood is low.</div>
          </Point>
          <Point>
            <Emoji>&#128564;</Emoji>{' '}
            <div>You never seem to get enough sleep.</div>
          </Point>
          <Point>
            <Emoji>&#128555;</Emoji>{' '}
            <div>You have poor energy levels thoughout the day.</div>
          </Point>
          <Point>
            <Emoji>&#128170;</Emoji>{' '}
            <div>You want to feel healthy and strong.</div>
          </Point>
        </Section>
        <Section>
          <SubHeader>Take Action</SubHeader>
          <Text>
            Daily anchor is a{' '}
            <Highlight>free wellbeing action tracker</Highlight>.
          </Text>
          <Text>It's simple and basic, but simple and basic works.</Text>
          <Text>
            You'll track key actions based on science that you'll do everyday to
            improve how you feel and function.
          </Text>
          <Text>
            We've also added a bonus 26 day{' '}
            <Highlight>wellbeing action course</Highlight> to introduce you to
            methods and activities that you may not have done before.{' '}
          </Text>
          {callToAction('Try for free')}
        </Section>
      </Wrap>
      <Footer />
    </>
  );
}

const Wrap = styled.div`
  min-height: 100vh;
  padding: 5rem 60px 4rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 575px) {
    padding: 1rem 20px 4rem;
  }
`;

const Section = styled.div`
  padding: 75px 0 0;
  width: 65%;
  position: relative;

  @media (max-width: 1165px) {
    width: 80%;
  }

  @media (max-width: 800px) {
    width: 100%;
    padding: 50px 0 0;
  }
`;

const Header = styled.h1`
  margin: 2rem 0;
  line-height: 1.15;
  font-size: 3rem;
`;

const Gradient = styled.span`
  --start-color: #2d545e;
  --end-color: #5f9ea0;
  background-image: linear-gradient(
    90deg,
    var(--start-color),
    var(--end-color)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Text = styled.p`
  font-size: 1.25em;
  color: #6b695e;
  line-height: 28px;
  letter-spacing: 0.5px;
`;

const Highlight = styled.span`
  font-weight: 700;
  color: #2d545e;
`;

const MainButton = styled.button`
  background: #2d545e;
  font-weight: 700;
  font-size: 1.25em;
  border-radius: 5px;
  width: 180px;

  padding: 12px 20px;
  border: none;
  box-shadow: 6px 6px 0 0 rgb(0 0 0 / 20%);

  :hover {
    cursor: pointer;
  }
`;

const ButtonWrap = styled.div`
  margin-top: 45px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: white;
`;

const SmallText = styled.div`
  font-size: 0.75em;
  color: #6b695e;
  letter-spacing: 0.7px;
  margin-top: 12px;
`;

const Point = styled.div`
  font-size: 1.25em;
  color: #6b695e;
  line-height: 28px;
  letter-spacing: 0.5px;
  margin-bottom: 25px;
  display: flex;
`;

const Emoji = styled.div`
  margin-right: 15px;
`;

const SubHeader = styled.h2`
  margin: 2rem 0;
  line-height: 1.15;
  font-size: 2rem;
`;
