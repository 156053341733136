import { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { animated } from 'react-spring';

import blueBush from '../../styles/svgs/bluebush.svg';
import arrow from '../../styles/svgs/arrow.svg';
import orange from '../../styles/svgs/orange.svg';
import GratitudesList from './GratitudesList';
import Confetti from 'react-confetti';
import Footer from '../Components/Footer';

import useGetAnimation from './useGetAnimation';

function AnchorComponent({
  dailyAnchor,
  handleAnchor,
  handleGratitude,
  gratitude,
  quote,
  currentAction,
  showConfetti,
  triggerBoop,
  isBooped,
  disableSubmit,
}) {
  const {
    move = false,
    mind = false,
    mellow = false,
    gratitudes,
  } = dailyAnchor || {};

  const height = window.screen.height;
  const width = window.screen.width;

  const { moveStyle, mindStyle, mellowStyle } = useGetAnimation(
    isBooped,
    dailyAnchor
  );

  return (
    <>
      {showConfetti && (
        <Confetti
          width={width}
          height={height}
          recycle={false}
          numberOfPieces="500"
        />
      )}
      <Wrap>
        <Header>
          <TodaysAction>
            <StyledLink to="/course">
              <Pushable>
                <Front>{currentAction?.action}</Front>
              </Pushable>
            </StyledLink>
          </TodaysAction>
        </Header>
        <InnerWrap>
          <div>
            <Actions>
              <animated.span
                style={moveStyle}
                onClick={() => triggerBoop('move')}
              >
                <CircleButton
                  done={move}
                  onClick={(e) => handleAnchor(e, 'move')}
                >
                  <ButtonText>Move</ButtonText>
                </CircleButton>
              </animated.span>
              <animated.span
                style={mindStyle}
                onClick={() => triggerBoop('mind')}
              >
                <CircleButton
                  done={mind}
                  onClick={(e) => handleAnchor(e, 'mind')}
                >
                  <ButtonText>Mind</ButtonText>
                </CircleButton>
              </animated.span>
              <animated.span
                style={mellowStyle}
                onClick={() => triggerBoop('mellow')}
              >
                <CircleButton
                  done={mellow}
                  onClick={(e) => handleAnchor(e, 'mellow')}
                >
                  <ButtonText>Mellow</ButtonText>
                </CircleButton>
              </animated.span>
            </Actions>
            <GratitudeInputWrap onSubmit={(e) => handleAnchor(e, 'gratitude')}>
              <Input
                placeholder="What are you grateful for today?"
                onChange={handleGratitude}
                value={gratitude}
              />
              <BushWrap>
                <BlueBush src={blueBush} alt="bush" />
              </BushWrap>
            </GratitudeInputWrap>
            <GratitudesList gratitudes={gratitudes} />
            <SausageWrap>
              <Orange src={orange} alt="Sausage" />
            </SausageWrap>
          </div>
        </InnerWrap>
      </Wrap>
      <Footer />
    </>
  );
}

export default AnchorComponent;

const Wrap = styled.div`
  min-height: 100vh;
`;

const Header = styled.div`
  background: #2d545e;
  color: white;
  padding: 0 20px 20px;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
  position: relative;

  @media (min-width: 769px) {
    background: unset;
    margin-top: 30px;
    padding-bottom: 0;
  }
`;

const Title = styled.div`
  font-size: 1em;
  font-weight: 700;
  margin-bottom: 6px;
  margin-top: -4px;

  @media (min-width: 575px) {
    display: flex;
    justify-content: center;
    color: black;
    margin-bottom: 15px;
  }
`;

const InnerWrap = styled.div`
  padding: 20px;

  @media (min-width: 575px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const TodaysAction = styled.span`
  font-weight: 700;
  display: flex;
  justify-content: center;
  position: relative;

  @media (min-width: 769px) {
    margin-right: unset;
  }
`;

const BlueBush = styled.img`
  height: 50px;
`;

const Orange = styled.img`
  height: 100px;
`;

const SausageWrap = styled.div`
  position: absolute;
  left: -65px;
  top: 223px;
`;

const BushWrap = styled.div`
  position: absolute;
  right: 2px;
  top: 60px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 20px;
  position: relative;

  @media (min-width: 575px) {
    padding: 25px 0;
  }
`;

const CircleButton = styled.button`
  border-radius: 50%;
  height: 80px;
  width: 80px;
  border: 1.5px solid #000000;
  background: transparent;
  color: black;
  font-family: 'Satoshi', sans-serif;

  :focus:not(:focus-visible) {
    outline: none;
  }

  ${({ done }) =>
    done ? 'background:rgb(234, 221, 202, 1);' : 'background;transparent;'};

  @media (min-width: 575px) {
    height: 95px;
    width: 95px;
  }

  @media (max-width: 350px) {
    height: 60px;
    width: 60px;
  }
`;

const ButtonText = styled.div`
  font-size: 1.1em;
`;

const GratitudeInputWrap = styled.form`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  position: relative;
`;

const Input = styled.input`
  height: 32px;
  border-radius: 5px;
  width: 100%;
  padding-left: 10px;
  border: 1px solid black;
  font-size: 16px;

  @media (min-width: 575px) {
    width: 375px;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
`;

const Quote = styled.div`
  background: #2d545e;
  position: sticky;
  bottom: 0;
  color: #ffff;
  padding: 10px 20px;
  text-align: left;
  line-height: 22px;
  letter-spacing: 0.5px;
  border-radius: 35px 35px 0 0;

  @media (min-width: 769px) {
    max-width: 400px;
    margin: auto;
    border-radius: 35px;
    bottom: 20px;
  }
`;

const QuoteInner = styled.div`
  @media (min-width: 769px) {
    display: flex;
    flex-direction: column;
  }
`;

const QuoteTitle = styled.div`
  font-weight: 700;
  display: flex;
  justify-content: space-between;
`;

const Author = styled.div`
  font-style: italic;
  font-size: 0.875em;
  margin-top: 5px;
`;

const QuoteWrap = styled.div`
  margin-top: 10px;
`;

const Pushable = styled.button`
  background: rgb(234, 221, 202, 0.5);
  border-radius: 12px;
  border: none;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  transition: filter 600ms;

  user-select: none;

  :hover {
    transition: filter 250ms;
    filter: brightness(102%);
  }
`;

const Front = styled.span`
  display: block;
  padding: 12px 42px;
  border-radius: 12px;
  font-size: 1.25rem;
  background: rgb(234, 221, 202, 1);
  color: black;
  transform: translateY(-6px);

  will-change: transform;
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);

  :hover {
    transform: translateY(-8px);
    filter: brightness(102%);
    transition: filter 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
  }

  :active {
    transform: translateY(-2px);
    transition: transform 34ms;
  }

  :focus:not(:focus-visible) {
    outline: none;
  }
`;
