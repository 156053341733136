import { Fragment } from 'react';
import styled from 'styled-components';

import lightning from '../../styles/svgs/lightning.svg';
import circles from '../../styles/svgs/circles.svg';
import star from '../../styles/svgs/star.svg';
import Footer from '../Components/Footer';

function CourseComponent({
  courseContent,
  currentAction,
  openModal,
  handleSubmit,
  handleAction,
  todaysAction,
  fullCourseLength,
  disableButton,
}) {
  const placeholder = disableButton
    ? "Great work, you're done for today!"
    : 'What did you do for todays action?';

  function renderCourse() {
    return courseContent.map((action, index) => {
      const isCurrent = action.id === currentAction.todaysActionId;
      const isLast = courseContent.length === index + 1;
      const done = action.id < currentAction.todaysActionId;

      return (
        <Fragment key={action.id}>
          <div>
            <CircleButton
              isCurrent={isCurrent}
              completed={isCurrent && currentAction.completed}
              done={done}
              onClick={() => openModal(action)}
            >
              <ActionText isCurrent={isCurrent} done={done}>
                {action.action}
              </ActionText>
            </CircleButton>
          </div>
          {!isLast && (
            <LineWrap>
              <VerticalLine />
            </LineWrap>
          )}
        </Fragment>
      );
    });
  }

  return (
    <>
      <Wrap>
        <Header>
          <div>
            <CirclesWrap>
              <Circles src={circles} alt="Circles" />
            </CirclesWrap>
            <Title>
              {currentAction.todaysActionId - 1}/{fullCourseLength} day
              wellbeing course
            </Title>
            <InputWrap onSubmit={handleSubmit}>
              <Input
                placeholder={placeholder}
                disabled={disableButton}
                onChange={handleAction}
                value={todaysAction}
              />
            </InputWrap>
          </div>
        </Header>
        <InnerWrap>
          <CourseWrap>
            <DesktopLineWrap>
              <VerticalLine />
            </DesktopLineWrap>
            {renderCourse()}
          </CourseWrap>
          <StarWrap>
            <Star src={star} alt="Star" />
          </StarWrap>
          <LightningWrap>
            <Lightning src={lightning} alt="Lightning" />
          </LightningWrap>
        </InnerWrap>
      </Wrap>
      <Footer />
    </>
  );
}

export default CourseComponent;

const Wrap = styled.div`
  min-height: 100vh;
`;

const Header = styled.div`
  background: #2d545e;
  color: white;
  padding: 0 20px 20px;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;

  @media (min-width: 575px) {
    display: flex;
    justify-content: center;
  }

  @media (min-width: 769px) {
    background: transparent;
    margin-top: 30px;
  }
`;

const Title = styled.div`
  font-size: 1em;
  font-weight: 700;

  @media (min-width: 769px) {
    color: black;
  }
`;

const InnerWrap = styled.div`
  padding: 0 20px 100px;
`;

const Lightning = styled.img`
  height: 60px;

  @media (min-width: 575px) {
    height: 100px;
  }
`;

const LightningWrap = styled.div`
  position: absolute;
  top: 450px;
  right: 5px;
`;

const Star = styled.img`
  height: 60px;

  @media (min-width: 575px) {
    height: 100px;
  }
`;

const StarWrap = styled.div`
  position: absolute;
  top: 300px;
  left: -12px;
`;

const Circles = styled.img`
  height: 80px;

  @media (max-width: 700px) {
    height: 40px;
  }
`;

const CirclesWrap = styled.div`
  position: absolute;
  right: 4px;

  @media (max-width: 700px) {
    top: 70px;
  }
`;

const CircleButton = styled.button`
  border-radius: 50%;

  ${({ isCurrent }) =>
    isCurrent &&
    'height:140px; width: 140px; border: 2px solid black;background: #eaddca;'};

  ${({ isCurrent }) =>
    !isCurrent &&
    'height:100px; width: 100px; border: 1px solid #000000; background: transparent;'};

  ${({ done }) =>
    done && 'height: 90px; width:90px; border: 1.5px solid lightGrey;'};
`;

const ActionText = styled.div`
  ${({ isCurrent }) =>
    isCurrent && 'font-size: 1.125em; font-weight: 700; color: black;'};
  ${({ done }) => done && 'color: lightGrey;'};
`;

const CourseWrap = styled.div`
  text-align: center;
`;

const DesktopLineWrap = styled.div`
  display: flex;
  justify-content: center;

  @media (min-width: 769px) {
    display: none;
  }
`;

const LineWrap = styled.div`
  display: flex;
  justify-content: center;
`;

const VerticalLine = styled.div`
  height: 20px;
  border-left: 2px solid lightgrey;
`;

const InputWrap = styled.form`
  margin-top: 26px;
  display: flex;
  justify-content: center;
  position: relative;
`;

const Input = styled.input`
  height: 32px;
  border-radius: 5px;
  width: 100%;
  padding-left: 10px;
  border: 1px solid black;
  background: transparent;
  color: white;
  font-size: 16px;

  ::placeholder {
    color: white;
    opacity: 1;
  }

  :disabled {
    background: transparent;
  }

  @media (min-width: 575px) {
    width: 360px;
  }

  @media (min-width: 769px) {
    color: black;

    ::placeholder {
      color: black;
      opacity: 1;
    }
  }
`;

const StyledButton = styled.button`
  width: 42px;
  height: 42px;
  border-radius: 5px;
  border: 1px solid black;
  font-size: 20px;
  margin-left: 6px;
  background: transparent;
  padding: 0;
  ${({ done }) => done && 'border: 1px solid teal; color: teal;'};

  @media (max-width: 575px) {
    height: 44px;
  }
`;

const Emoji = styled.div`
  ${({ disabled }) =>
    disabled && 'color: transparent;text-shadow: 0 0 0 black;'};
`;
