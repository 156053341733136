import styled from 'styled-components';
import { Modal, Box } from '@mui/material';

function ActionModal({ modalOpen, handleClose, action }) {
  const { info, action: actionName } = action;

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 280,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Title>{actionName}</Title>
        <div>{info}</div>
      </Box>
    </Modal>
  );
}

export default ActionModal;

const Title = styled.div`
  font-weight: 700;
  font-size: 1.5em;
  margin-bottom: 20px;
`;
