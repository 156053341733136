import { useState, useEffect } from 'react';
import AnchorComponent from './AnchorComponent';
import moment from 'moment';
import { FIRST_ACTION } from '../constants';
import useSound from 'use-sound';
import pop from '../sounds/pop.flac';
import lowpop from '../sounds/lowpop.wav';

function AnchorContainer() {
  const [dailyAnchor, setDailyAnchor] = useState({
    move: false,
    mind: false,
    mellow: false,
    gratitudes: [],
  });
  const [gratitude, setGratitude] = useState('');
  const [currentAction, setCurrentAction] = useState();
  const [showConfetti, setShowConfetti] = useState(false);
  const [todaysQuote, setTodaysQuote] = useState();
  const [isBooped, setIsBooped] = useState({
    move: false,
    mind: false,
    mellow: false,
  });

  const [playClick] = useSound(pop);
  const [playUnClick] = useSound(lowpop);

  const disableSubmit = !gratitude;

  const today = new Date();
  const storedAnchor = localStorage.getItem('dailyAnchor');
  const storedDate = localStorage.getItem('today');
  // const storedQuote = localStorage.getItem('quote');
  // const quote = storedQuote !== 'undefined' && JSON.parse(storedQuote);
  const momentToday = moment(today).format('YYYY-MM-DD');
  const momentStoredDate =
    storedDate !== 'undefined' &&
    moment(JSON.parse(storedDate)).format('YYYY-MM-DD');
  const sameDay = moment(momentToday).isSame(momentStoredDate);
  const storedAction = localStorage.getItem('currentAction');

  useEffect(
    function initAction() {
      if (storedAction && Object.values(storedAction).length > 0) {
        setCurrentAction(JSON.parse(storedAction));
      } else {
        localStorage.setItem('currentAction', JSON.stringify(FIRST_ACTION));
        setCurrentAction(FIRST_ACTION);
      }
    },
    [storedAction, setCurrentAction]
  );

  useEffect(function initToday() {
    if (!sameDay) {
      // localStorage.setItem('quote', JSON.stringify(todaysQuote));
      localStorage.setItem('today', JSON.stringify(today));
      const savedAnchor =
        storedAnchor &&
        Object.values(storedAnchor).length > 0 &&
        JSON.parse(storedAnchor);

      localStorage.setItem(
        'dailyAnchor',
        JSON.stringify({
          move: false,
          mind: false,
          mellow: false,
          gratitudes: savedAnchor?.gratitudes || [],
        })
      );
    }
  }, []);

  useEffect(
    function initAnchors() {
      if (storedAnchor && Object.values(storedAnchor).length > 0) {
        setDailyAnchor(JSON.parse(storedAnchor));
      }
    },
    [storedAnchor, setDailyAnchor]
  );

  useEffect(() => {
    const allFalse = Object.values(isBooped).every((type) => !type);

    if (allFalse) {
      return;
    }

    const timeoutId = window.setTimeout(() => {
      setIsBooped({ move: false, mind: false, mellow: false });
    }, 150);
    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [isBooped]);

  const triggerBoop = (type) => {
    setIsBooped({ [type]: true });
  };

  function handleAnchor(e, type) {
    e.preventDefault();
    if (type === 'gratitude') {
      const gratitudes = dailyAnchor?.gratitudes || [];
      if (gratitudes.length >= 50) {
        gratitudes.pop();
      }

      gratitudes.unshift(gratitude);
      localStorage.setItem(
        'dailyAnchor',
        JSON.stringify({
          ...dailyAnchor,
          gratitudes: gratitudes,
        })
      );
      localStorage.setItem('lastInput', JSON.stringify(today));
      setGratitude('');
      return;
    }

    if (!dailyAnchor[type]) {
      playClick();
    } else {
      playUnClick();
    }
    localStorage.setItem('lastInput', JSON.stringify(today));
    localStorage.setItem(
      'dailyAnchor',
      JSON.stringify({
        ...dailyAnchor,
        [type]: !dailyAnchor[type],
      })
    );

    const updatedAnchor = { ...dailyAnchor, [type]: !dailyAnchor[type] };
    const actionsComplete =
      updatedAnchor.move && updatedAnchor.mind && updatedAnchor.mellow;
    setShowConfetti(actionsComplete);
    setDailyAnchor(updatedAnchor);
  }

  function handleGratitude(e) {
    e.preventDefault();
    const { value } = e.target;

    setGratitude(value);
  }

  return (
    <AnchorComponent
      dailyAnchor={dailyAnchor}
      handleAnchor={handleAnchor}
      handleGratitude={handleGratitude}
      gratitude={gratitude}
      quote={todaysQuote}
      currentAction={currentAction}
      showConfetti={showConfetti}
      triggerBoop={triggerBoop}
      isBooped={isBooped}
      disableSubmit={disableSubmit}
    />
  );
}

export default AnchorContainer;
