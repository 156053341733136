import styled from 'styled-components';
import { Link } from 'react-router-dom';

export default function AboutPage() {
  return (
    <Wrap>
      <Inner>
        <Title>About daily anchor</Title>
        <Subtitle>Why</Subtitle>
        <Text>
          Hey, <ALink href="https://twitter.com/paulbobrayner">I'm Paul</ALink>.
          I created daily anchor due to a personal need to track daily actions
          that support my physical but more importantly my mental health.
        </Text>
        <Text>
          Doing the daily anchor each day has helped to manage anxiety and
          stress not only on normal days but also during periods of my life
          which have been tough.
        </Text>
        <Text>
          My partner and I had 5 cancer deaths in our families in as many years,
          including both of our dads. To say it was tough is probably an
          understatement.
        </Text>
        <Text>
          It turns out that having an 'anchor' to focus on each day that
          involved real actions like exercise, meditation and calming activities
          for sleep helped to keep my stress and anxiety to a manageable level.
        </Text>
        <Text>
          I originally started with good old pen and paper, but being a software
          developer I thought an app would allow for some cool additions.
        </Text>
        <Subtitle>How does it work</Subtitle>
        <Text>Your daily anchor has 4 key aspects.</Text>
        <Text>Move, Mind, Mellow and Gratitude.</Text>
        <Text>
          The focus is on action. Actually performing actions that support the
          body and mind.
        </Text>
        <Text>
          The aim is to do one form of move, mind and mellow each day, along
          with observing and noting down something you're grateful for.
        </Text>
        <Text>
          Move, mind and mellow are all actions that can cause our state to
          change or adjust. The aim of state change is to hopefully improve your
          mood, to feel more peaceful and most importantly to reduce anxiety and
          stress.
        </Text>
        <Text>
          Once you've performed one of these, check off the circle like you
          would a todo.
        </Text>
        <Text>
          The move, mind and mellow anchors reset everyday, whereas you get a
          little bit of history with the gratitudes.
        </Text>
        <Subtitle>Move</Subtitle>
        <Text>You can do anything movement based in your day.</Text>
        <Text>
          This can be anything such as walking, dancing, gym classes, weight
          lifting or yoga.
        </Text>
        <Text>
          Anything that raises your heart rate and gets you a bit out of breath.
          Ideally we want a level of intensity that will push you somewhat.
        </Text>
        <Subtitle>Mind</Subtitle>
        <Text>Mind simply means mindfulness.</Text>
        <Text>
          Mindfulness has been shown to promote a sense of calm, reduce anxiety
          and stress.
        </Text>
        <Text>
          Do activities that focus the mind and thought on one key activity.
          This includes activities such as meditation, breathework, music
          mindfulness and even yoga and stretching.
        </Text>
        <Text>
          For simple guides to meditation check out apps like headspace or calm.
          I may add some mediatations here if requested.
        </Text>
        <Subtitle>Mellow</Subtitle>
        <Text>Mellow is all about sleep.</Text>
        <Text>
          Sleep is one of the most important elements of wellbeing, mood and
          performance.
        </Text>
        <Text>
          Mellow is any action that supports or sets you up for good sleep.
          Essentially what you do in the run up to sleep.
        </Text>
        <Text>
          Actions for this include stretching, accupressure with a spike mat,
          nothing time, breathework, meditation, going to bed at a time that
          allows you 7hrs+ of sleep, reading (fiction).
        </Text>
        <Text>
          This could also include things to avoid - avoiding screens (30-60 mins
          pre bed), alcohol, caffeine in the afternoon, high intensity exercise
          close to bedtime, eating within 60-90mins of bed.
        </Text>
        <Text>
          A good goal would be to turn off the TV and avoid screens 45mins
          before bed, do some breathework then a little bit of reading.
        </Text>
        <Subtitle>Gratitude</Subtitle>
        <Text>
          There's more and more evidence that grateful people and those that
          journal gratitude are happier, less anxious and more resilient.
        </Text>
        <Text>
          <ALink href="https://www.mindful.org/the-science-of-gratitude/">
            Some more info here.
          </ALink>
        </Text>
        <Text>
          All you need to do at any point in the day is write down something
          that you are grateful for that day.
        </Text>
        <Text>This can be big or small, there are no rules.</Text>
        <Text>
          The aim is to notice it, name it and try and feel the gratitude.
        </Text>
        <Text>That should be enough info to get you going.</Text>
        <ButtonWrap>
          {' '}
          <MainButton>
            <StyledLink to="/">Get Started</StyledLink>
          </MainButton>
        </ButtonWrap>

        <Subtitle>Say hi</Subtitle>
        <Text>
          Any questions then hit me up -{' '}
          <ALink href="That should be enough info to get you going.">
            Twitter
          </ALink>{' '}
          or <ALink href="mailto: paul@frontendco.dev">Email</ALink>
        </Text>
      </Inner>
    </Wrap>
  );
}

const Wrap = styled.div`
  padding: 40px 20px 120px;

  @media (min-width: 575px) {
    padding: 60px 20px 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Inner = styled.div`
  @media (min-width: 575px) {
    width: 90%;
  }
  @media (min-width: 850px) {
    width: 65%;
  }
`;

const Title = styled.h1`
  font-size: 1.75em;
  font-weight: 700;
  margin-bottom: 20px;

  @media (min-width: 575px) {
  }
`;

const Subtitle = styled.h2`
  font-size: 1.25em;
  font-weight: 700;
  margin-top: 45px;
`;

const Text = styled.p``;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: white;
  font-weight: 700;
`;

const ALink = styled.a`
  color: #2d545e;
  font-weight: 700;
`;

const MainButton = styled.button`
  background: #2d545e;
  font-weight: 700;
  font-size: 1.25em;
  border-radius: 5px;
  color: white !important;

  padding: 12px 20px;
  border: none;

  :hover {
    cursor: pointer;
  }
`;

const ButtonWrap = styled.div`
  margin-top: 40px;
`;
