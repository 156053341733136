export const courseContent = [
  {
    id: 26,
    action: 'What worked',
    info: "The wellbeing action course is now at an end. Todays task is to think back to all the actions that you've done so far. Which ones would you like to continue? Which were most effective? Make a note of them and add them to your own daily anchor.",
  },
  {
    id: 25,
    action: 'Yoga',
    info: 'Try out some yoga, watch a 15 minute yoga video and stretch out.',
  },
  {
    id: 24,
    action: 'Break routine',
    info: 'Today or tomorrow, do something that breaks your normal routine. Increasing novelty in our lives can promote happiness. Eg. Go for a walk midday, work from a co-working spot, meet up with a friend for a pre-work walk/run/cycle.',
  },
  {
    id: 23,
    action: 'Gratitude',
    info: 'Extend your daily gratitude practise. Write down 3-5 things your grateful for today. Small or big. Really think about these and try your best to internalise and meditate on them - how did you feel at the time, why are you grateful, how could you expand that gratitude?',
  },
  {
    id: 22,
    action: 'Roll out',
    info: 'Grab a tennis/hockey/lacrosse ball and perform some self massage. Roll out the glutes, the calfs and the upper back. Get in all the tight spots - https://www.youtube.com/watch?v=QQRhVkiIe0w. This is great for mobility and relaxation.',
  },
  {
    id: 21,
    action: 'Kindness',
    info: "Do a random act of kindness today for someone. Look for opportunities where you can help people or be kind in some way no matter how small. Do this unconditionally (you don't expect anything in return).",
  },
  {
    id: 20,
    action: 'Self compassion',
    info: "Self compassion is a powerful way to combat unnecessary and potentially damaging negative self talk. It basically means being kind to yourself (not beating yourself up) and treating yourself like you would a friend. Task - Write down - in a positive way - a characteristic about yourself or something that's going on in your life. ie 'I'm doing great with...', 'You're doing just fine', 'Keep going'.",
  },
  {
    id: 19,
    action: 'Journal',
    info: 'Towards the end of the day, write down 3 things that went well for you today. These can be small, trivial, big or important.',
  },
  {
    id: 18,
    action: 'No phone',
    info: 'Reduce your phone use today. A simple strategy is to not use your phone for a set amount of time before bed and after waking up. Ie no phone for the first and last hour of the day.',
  },
  {
    id: 17,
    action: 'Forest bathe',
    info: 'Find a forest or green space near you. Go for a 30 minute walk. how do you feel after?',
  },
  {
    id: 16,
    action: 'Breathe',
    info: 'Take out 3-5 minutes to perform some breathework. Try some box breathing. 5 seconds breath in, hold your breathe for 5 seconds, breath out for 5 seconds, hold your breath for 5 seconds and repeat.  ',
  },
  {
    id: 15,
    action: 'Sleep - room',
    info: 'Take steps to improve your sleep environment. Dark room (blackout curtains, eye mask), cool temperature (light blanket, air flow), manage noise (earplugs), comfortable bed.',
  },
  {
    id: 14,
    action: 'Sleep - relax',
    info: 'Try something to purposefully relax you before bed. Ie meditation, stretching, accupressure mat. Do this activity for 15-20mins then go to bed or read for a short period of time.',
  },
  {
    id: 13,
    action: 'Sleep - bedtime',
    info: 'Plan your bedtime so you get atleast 7hrs and ideally 8hrs of sleep. Have a wind down period 30-60 mins before bed without screens.',
  },
  {
    id: 12,
    action: 'Exercise',
    info: 'Do an activity which is slightly more intense today - using weights, going for a run or cycle for 20 minutes or more. Something which pushes you a bit more than last time. Notice how you feel after.',
  },
  {
    id: 11,
    action: 'Cold',
    info: 'Lets progress the cold action today. Aim for a 30 second blast of cold water after your regular shower.',
  },
  {
    id: 10,
    action: 'No social media',
    info: "This isn't so much of an action as an anti-action. Challenge yourself and see how long you can go without social media today. Aim for the whole working day. If that seems too tough. Aim for 2-3 hours. No instagram, pinterest, twitter, tik tok, linked in, youtube etc. Notice how you feel at the end of the day without it. ",
  },
  {
    id: 9,
    action: 'Stretch',
    info: 'Do 10 minutes of stretching. Hold each stretch for 60-90 seconds. Slowly ease into each stretch, avoid pain. Glute stretch, hip flexor stretch, calf stretch, hamstring stretch.',
  },
  {
    id: 8,
    action: 'Mind',
    info: 'Do 10 minutes of meditation. Same as before. Focus on the breath. Count up to 10 and repeat.',
  },

  {
    id: 7,
    action: 'Heat',
    info: 'Get some heat. Have a warm bath, or use a sauna. ',
  },
  {
    id: 6,
    action: 'Mind',
    info: 'Try a simple 5 minute meditation. https://www.headspace.com/ or https://www.calm.com/ .  Alternatively, sit with your eyes shut, count your in and out breaths up to 10 and repeat. 1 when breathing in, 2 breathing out, 3 breathing in.....etc.  If you get distracted then redirect your attention back to the count.',
  },
  {
    id: 5,
    action: 'Nothing',
    info: 'Do absolutely nothing for 10 minutes. Just sit. This is actually a lot tougher than it may seem.',
  },
  {
    id: 4,
    action: 'Breathe',
    info: 'Do some breathwork for 3 minutes. use https://www.breathwrk.com/. Or take a slow 5 second breath in and a slow 5 second breath out and repeat',
  },

  {
    id: 3,
    action: 'Exercise for 20',
    info: 'Do some sort of exercise that raises your heart rate for atleast 20 minutes.',
  },
  {
    id: 2,
    action: 'Cold',
    info: 'Take a 15+ second cold shower. At the end of your regular shower, turn the heat off and shower in the cold for atleast 15 seconds.',
  },
  {
    id: 1,
    action: 'Walk',
    info: 'Take a 15+ minute walk today outside. Take a break to get outside in the fresh air, take in your surroundings and walk for walkings sake. Bonus points if you do a bit of forest bathing (Shinrin-yoku) - basically walking in a green space. No music, no podcasts.',
  },
];
