import { useSpring } from 'react-spring';

export default function useGetAnimation(isBooped, dailyAnchor) {
  const { move = false, mind = false, mellow = false } = dailyAnchor || {};

  const getTransform = (type, anchor) => {
    if (isBooped[type] && anchor) {
      return `translate(0px, 0px)rotate(20deg)scale(1.07)`;
    } else if (isBooped[type] && !anchor) {
      return `translate(0px, 0px)rotate(-20deg)scale(0.93)`;
    } else {
      return `translate(0px, 0px)rotate(0deg)scale(1)`;
    }
  };

  const moveStyle = useSpring({
    display: 'inline-block',
    backfaceVisibility: 'hidden',
    transform: getTransform('move', move),
    config: {
      tension: 300,
      friction: 7,
    },
  });

  const mindStyle = useSpring({
    display: 'inline-block',
    backfaceVisibility: 'hidden',
    transform: getTransform('mind', mind),
    config: {
      tension: 300,
      friction: 7,
    },
  });

  const mellowStyle = useSpring({
    display: 'inline-block',
    backfaceVisibility: 'hidden',
    transform: getTransform('mellow', mellow),
    config: {
      tension: 300,
      friction: 7,
    },
  });

  return { moveStyle, mindStyle, mellowStyle };
}
