import styled from 'styled-components';
import TwitterIcon from '@mui/icons-material/Twitter';

export default function Footer() {
  return (
    <Wrap>
      <LinkTag
        target="_blank"
        rel="noopener noreferrer"
        href="https://prayner.com"
      >
        Built by Prayner{' '}
      </LinkTag>

      <Icon>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://twitter.com/paulbobrayner"
        >
          <TwitterIcon />
        </a>
      </Icon>
    </Wrap>
  );
}

const Wrap = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-size: 0.875em;
  background: #fffafa;
  line-height: 35px;
  bottom: 0;
  width: 100%;
`;

const Icon = styled.div`
  margin: 8px 0 0 12px;

  svg {
    height: 0.75em;
    width: 0.75em;
    color: black;
  }
`;

const LinkTag = styled.a`
  color: black;
  text-decoration: none;
`;
