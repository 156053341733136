import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MenuIcon from '@mui/icons-material/Menu';
import AnchorIcon from '@mui/icons-material/Anchor';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import BuildIcon from '@mui/icons-material/Build';
import InfoIcon from '@mui/icons-material/Info';
import { useMatch } from 'react-router-dom';
import FavoriteIcon from '@mui/icons-material/Favorite';

export default function Nav() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const landingPage = useMatch('/');

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem>
          <StyledLink to="/">
            <AnchorIcon />
            <NavText>Daily anchor</NavText>
          </StyledLink>
        </ListItem>
        <ListItem>
          <StyledLink to="/course">
            <MenuBookIcon />
            <NavText>Action course</NavText>
          </StyledLink>
        </ListItem>
        <ListItem>
          <StyledLink to="/about">
            <InfoIcon />
            <NavText>About</NavText>
          </StyledLink>
        </ListItem>
        <ListItem>
          <StyledATag
            target="_blank"
            href="https://prayner.canny.io/feature-requests"
          >
            <BuildIcon />
            <NavText>Send feedback</NavText>
          </StyledATag>
        </ListItem>
        <ListItem>
          <StyledATag
            target="_blank"
            href="https://senja.io/p/soapboxer/r/hSnbDa"
          >
            <FavoriteIcon />
            <NavText>Send love</NavText>
          </StyledATag>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment key="right">
        <Header isLandingPage={landingPage}>
          <AnchorLogo>
            <StyledLink to="/">
              <AnchorIcon />
            </StyledLink>
          </AnchorLogo>
          <MenuIcon onClick={toggleDrawer('right', true)} />
        </Header>
        <SwipeableDrawer
          anchor="right"
          open={state['right']}
          onClose={toggleDrawer('right', false)}
          onOpen={toggleDrawer('right', true)}
        >
          {list('right')}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background: #2d545e;
  margin-bottom: -1px;
`;

const AnchorLogo = styled.div`
  font-size: 1.125em;
  font-weight: 700;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
  display: flex;

  :hover {
    text-decoration: none;
    color: black;
  }
`;

const NavText = styled.div`
  font-weight: 700;
  margin-left: 10px;
`;

const StyledATag = styled.a`
  text-decoration: none;
  color: black;
  display: flex;

  :hover {
    text-decoration: none;
    color: black;
  }
`;
