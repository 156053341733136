import { useState, useEffect } from 'react';

import CourseComponent from './CourseComponent';
import { courseContent } from './courseData';
import ActionModal from './ActionModal';
import moment from 'moment';
import { FIRST_ACTION } from '../constants';

function CourseContainer() {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState();
  const [currentAction, setCurrentAction] = useState({});
  const [todaysAction, setTodaysAction] = useState();

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);
  const storedAction = localStorage.getItem('currentAction');
  const filtered = courseContent.filter(
    (action) => action.id <= currentAction.todaysActionId
  );
  const fullCourseLength = courseContent.length;

  const today = new Date();
  const storedDate = localStorage.getItem('today');
  const actionDate =
    currentAction.date && moment(currentAction?.date).format('YYYY-MM-DD');
  const momentStoredDate =
    storedDate !== 'undefined' &&
    moment(JSON.parse(storedDate)).format('YYYY-MM-DD');
  const disableButton = moment(actionDate).isSame(momentStoredDate);

  useEffect(
    function initAction() {
      if (storedAction && Object.values(storedAction).length > 0) {
        const stored = JSON.parse(storedAction);
        setCurrentAction(stored);
      } else {
        localStorage.setItem('currentAction', JSON.stringify(FIRST_ACTION));
        setCurrentAction(FIRST_ACTION);
      }
    },
    [storedAction, setCurrentAction]
  );

  function openModal(action) {
    setModalInfo(action);
    handleOpen();
  }

  function handleSubmit() {
    const nextAction = courseContent.find(
      (course) => course.id === currentAction.todaysActionId + 1
    );

    if (!nextAction) {
      localStorage.setItem('currentAction', JSON.stringify(FIRST_ACTION));
      FIRST_ACTION.date = today;
      setCurrentAction(FIRST_ACTION);
      setTodaysAction('');
      return;
    }

    const upNext = {
      todaysActionId: nextAction.id,
      action: nextAction.action,
      date: today,
    };

    localStorage.setItem('currentAction', JSON.stringify(upNext));
    setCurrentAction(upNext);
    setTodaysAction('');
  }

  function handleAction(e) {
    const { target } = e;
    setTodaysAction(target.value);
  }

  return (
    <>
      <CourseComponent
        courseContent={filtered}
        currentAction={currentAction}
        openModal={openModal}
        handleSubmit={handleSubmit}
        handleAction={handleAction}
        todaysAction={todaysAction}
        fullCourseLength={fullCourseLength}
        disableButton={disableButton}
      />
      {modalOpen && (
        <ActionModal
          action={modalInfo}
          handleClose={handleClose}
          modalOpen={modalOpen}
        />
      )}
    </>
  );
}

export default CourseContainer;
