import { Routes, Route, BrowserRouter } from 'react-router-dom';
import AnchorPage from './App/Pages/AnchorPage';
import CoursePage from './App/Pages/CoursePage';
import AboutPage from './App/Pages/AboutPage';
import Nav from './App/Components/Nav';
import LandingPage from './App/Pages/LandingPage';

function App() {
  return (
    <BrowserRouter>
      <Nav />
      <Routes>
        {/* <Route path="/" element={<LandingPage />} /> */}
        <Route path="/" element={<AnchorPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/course" element={<CoursePage />} />
        {/* <Route path="/focus" element={<FocusPage />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
